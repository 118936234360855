<template>
  <div :class="cols" class="mb-2">
    <div v-if="['number'].includes(field.type)">
      <label v-if="field.label != ''" :for="field.name" class="form-label">{{ field.label }}</label>
      <input @change="valueValidation" @input="valueValidation" :type="field.type" class="form-control" :class="cssClasses" :min="field.min" :max="field.max" v-model.number="myValue" :placeholder="field.placeholder" :name="field.name" :disabled="field.disabled" :readonly="field.readonly">
      <div v-if="showingErrorMessage" class="invalid-feedback">{{ errorMessage }}</div>
        <div class="text-muted" v-text="field.description || ''"></div>
    </div>
    <div v-if="['month','range','week','date','datetime-local','time'].includes(field.type)">
      <label v-if="field.label != ''" :for="field.name" class="form-label">{{ field.label }}</label>
      <input @change="valueValidation" @input="valueValidation" :type="field.type" class="form-control" :class="cssClasses" :min="field.min" :max="field.max" v-model="myValue" :placeholder="field.placeholder" :name="field.name" :disabled="field.disabled" :readonly="field.readonly">
      <div v-if="showingErrorMessage" class="invalid-feedback">{{ errorMessage }}</div>
      <div class="text-muted" v-text="field.description || ''"></div>
    </div>
    <div v-else-if="['text','email','password','tel','url','search','url'].includes(field.type)">
      <label  v-if="field.label != ''" :for="field.name" class="form-label">{{ field.label }}</label>
      <input @change="valueValidation" @input="valueValidation" :type="field.type" class="form-control" :class="cssClasses" v-model="myValue" :placeholder="field.placeholder" :maxlength="field.maxlength" :disabled="field.disabled" :readonly="field.readonly">
      <div v-if="showingErrorMessage" class="invalid-feedback">{{ errorMessage }}</div>
      <div class="text-muted" v-text="field.description || ''"></div>
    </div>
    <div v-else-if="field.type == 'color'">
      <label v-if="field.label != ''" :for="field.name" class="form-label">{{ field.label + ' (' + field.value + ')' }}</label>
      <input @change="valueValidation" @input="valueValidation" type="color" class="form-control" :class="cssClasses" v-model="myValue" :name="field.name" :disabled="field.disabled" :readonly="field.readonly">
      <div v-if="showingErrorMessage" class="invalid-feedback">{{ errorMessage }}</div>
      <div class="text-muted" v-text="field.description || ''"></div>
    </div>
    <div v-else-if="field.type == 'textarea'">
      <label v-if="field.label != ''" :for="field.name" class="form-label">{{ field.label }}</label>
      <textarea @change="valueValidation" @input="valueValidation" v-model="myValue" class="form-control" style="height:8rem;" :name="field.name" :disabled="field.disabled" :readonly="field.readonly"></textarea>
      <div v-if="showingErrorMessage" class="invalid-feedback">{{ errorMessage }}</div>
      <div class="text-muted" v-text="field.description || ''"></div>
    </div>
    <div v-else-if="field.type == 'select'">
      <label v-if="field.label != ''" :for="field.name" class="form-label">{{ field.label }}</label>
      <select class="form-select" :class="cssClasses" v-model="myValue" :multiple="field.multiple" :size="field.size" :name="field.name" @change="valueValidation" @input="valueValidation" :disabled="field.disabled">
        <option disabled value="">{{ field.placeholder }}</option>
        <option 
          v-for="option in field.options"
          :key="option.label"
          :value="option.value" 
          :selected="option.value == myValue"
          class="form-check"
        >{{ option.label }}</option>
      </select>
      <div v-if="showingErrorMessage" class="invalid-feedback">{{ errorMessage }}</div>
      <div class="text-muted" v-text="field.description || ''"></div>
    </div>
    <div v-else-if="['checkbox','radio'].includes(field.type)">
      <label v-if="field.label != ''" class="form-label">{{ field.label }}</label>
      <div v-for="radio in field.options" class="form-check" :key="radio.name">
        <input @change="valueValidation" @input="valueValidation" :type="field.type" class="form-check-input" v-model="myValue" :id="radio.name + radio.value" :checked="myValue == radio.value" :disabled="field.disabled" :readonly="field.readonly">
        <label :for="radio.name + radio.value" class="form-check-label">{{ radio.label }}</label>
      </div>
      <div v-if="showingErrorMessage" class="invalid-feedback">{{ errorMessage }}</div>
      <div class="text-muted" v-text="field.description || ''"></div>
    </div>
    <div v-else-if="['switch-checkbox','button-checkbox'].includes(field.type)">
      <FormField
        :field="getNewField('checkbox')"
        @value-changed="valueValidation"
      />
    </div>
    <div v-else-if="['switch-radio','button-radio'].includes(field.type)">
      <FormField
        :field="getNewField('radio')"
        @value-changed="valueValidation"
      />
    </div>
    <div v-else-if="['message'].includes(field.type)">
      <div class="text-muted" v-text="field.value || ''"></div>
    </div>
  </div>
</template>

<script>
import FormField from './FormField.vue'
export default {
  name: 'FormField',
  components: {
    FormField
  },
  props: {
    field: Object
  },
  watch: {
    field: function(){
      this.myValue = this.field.value;
      this.valueValidation();
    }
  },
  created: function(){
    this.valueValidation();
  },
  methods: {
    getNewField: function(newInner){
      let f = this.field;
      f.type = f.innerType;
      f.innerType = newInner;
      return f;
    },
    valueValidation: function(){
      let myInput = this.inputValue;
      let validationOK = true;
      let errDesc = '';

      if (validationOK && (this.field.required == true) && (myInput == null || myInput == '')){
        validationOK = false;
        errDesc = 'This field is required.'
      }

      if (validationOK){
        this.hideErrorMessage();
        this.emitValueChanged({
          field: this.field,
          newValue: myInput
        });
      } else {
        this.showErrorMessage(errDesc);
        this.emitValueError({
          field: this.field,
          newValue: errDesc,
          description: errDesc
        });
      }
    },
    showErrorMessage: function(message){
      this.errorMessage = message;
      this.showingErrorMessage = true;
    },
    hideErrorMessage: function(){
      this.showingErrorMessage = false;
    },
    emitValueChanged: function(value){
      this.$emit('value-changed', value);
    },
    emitValueError: function(value){
      this.$emit('value-error', value);
    }
  },
  computed: {
    cssClasses(){
      let css = [];
      if (this.showingErrorMessage && (this.field && this.field.showStatus)){ css.push('is-invalid'); }
      if (!this.showingErrorMessage && (this.field && this.field.showStatus)){ css.push('is-valid'); }

      return css.join(' ');
    },
    cols(){
      return 'col-' + this.field.cols;
    },
    inputValue(){
      let result = this.myValue;

      return result;
    }
  },
  data() {
    return {
      myValue: this.field.value,
      showingErrorMessage: false,
      errorMessage: ''
    }
  }
}
</script>

<style scoped>

</style>