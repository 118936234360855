<template>
  <div class="container-fluid">
    <div class="row g-0 p-3 ">
      <FormField
        v-if="searchField || false"
        :field="searchField"
        @value-changed="handleValueChanged"
        @value-error="handleValueError"
      />
    </div>
    <div class="row g-0 border-top">
      <ul class="list-unstyled">
        <SettingsPageSidebarFolder
          class="item"
          :item="sourceTree"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import SettingsPageSidebarFolder  from './SettingsPageSidebarFolder';
import FormField  from './FormField';
import { v4 as randomUUID } from 'uuid';

export default {
  name: 'SettingsPageSidebar',
  components: {
    FormField,
    SettingsPageSidebarFolder
  },
  methods: {
    handleValueChanged: function(){

    },
    handleValueError: function(){

    }
  },
  data() {
    return {
      searchField: {
        id: randomUUID(),
        name: 'settingssearch',
        label: 'Search',
        type: 'text',
        value: 'asd',
        placeholder: 'Search something in Settings.',
        maxlength: 200,
        disabled: false,
        readonly: false,
        required: false,
        showStatus: false,
        cols: 12
      },
      sourceTree: {
        name: 'Settings',
        children: [
          { name: 'Dashboard', goto: 'unkown' },
          { 
            name: 'Flow',
            children: [
              { name: 'General', goto: 'unkown'},
              { name: 'Activity', goto: 'settings-flow' }
            ]
          },
          { 
            name: 'Admin',
            children: [
              { name: 'Users', goto: 'unkown'},
              { name: 'Roles', goto: 'unkown'},
            ]
          }
        ]
      },
      showSidebar: true
    }
  },
  meta() {
    return {
      title: 'Settings'
    }
  }
}
</script>

<style>

</style>