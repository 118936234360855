<template>
  <div class="container-fluid">
    <div class="row p-3" style="background: #eee;">
      <div class="col-9">
        <h3>Flows</h3>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <router-link class="breadcrumb-item" :to="{ name:'home' }">Home</router-link>
            <li class="breadcrumb-item active">Flows</li>
          </ol>
        </nav>
      </div>
      <div class="col-3 align-items-center justify-content-end d-flex">
        <router-link class="btn btn-primary" :to="{ path:'/flow/new' }">New Flow</router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <FlowPageList />
      </div>
    </div>
  </div>
</template>

<script>
import FlowPageList from './FlowPageList.vue'
export default {
  components: {
    FlowPageList
  },
  meta() {
    return {
      title: 'Flows'
    }
  }
}
</script>

<style scoped>

</style>