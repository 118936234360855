<template>
  <div class="container-fluid h-100">
    <div class="row">
      <div class="col-12 p-3" style="background: #eee;">
        <h3>Dashboard</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
      
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardPage',
  data() {
    return {
      
    }
  },
  meta() {
    return {
      title: 'Dashboard'
    }
  }
}
</script>

<style>

</style>