<template>
  <li class="ps-3 my-2">
    
    <div
      v-if="isFolder"
      :class="componentClass"
      @click="handleClick">
      <i v-if="isOpen" class="fas fa-fw fa-angle-down"></i>
      <i v-if="!isOpen" class="fas fa-fw fa-angle-right"></i>
      {{ item.name }}
    </div>
    <router-link v-else class="nav-link" :class="componentClass" :to="redirectTo">
      <i class="fas fa-fw"></i>
      {{ item.name }}
    </router-link>

    <ul v-show="isOpen" v-if="isFolder" class="list-unstyled my-2">
      <SettingsPageSidebarFolder
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
      ></SettingsPageSidebarFolder>
    </ul>

  </li>
</template>

<script>
import SettingsPageSidebarFolder  from './SettingsPageSidebarFolder';

export default {
  name: 'SettingsPageSidebarFolder',
  components: {
    SettingsPageSidebarFolder
  },
  props: {
    item: Object
  },
  computed: {
    redirectTo: function(){
      return { 
        name: this.item.goto 
      };
    },
    hasSomeActiveChild: function(){
      
      function recursiveSearchActive(e){
        if (e.active){
          return true;
        }
        if (e.children){
          let r = e.children.some(recursiveSearchActive);
          return r;
        }
        return false;
      }

      return recursiveSearchActive(this.item);
    },
    componentClass: function(){
      let clase = ['p-2','root'];
      
      if(this.item.active){
        // clase.push('bg-primary text-light fw-bold');
      } else if (this.hasSomeActiveChild){
        clase.push('bg-light');
      } else {
        clase.push('text-dark');
      }

      return clase.join(" ");
    },
    isFolder: function () {
      return this.item.children &&
        this.item.children.length
    }
  },
  methods: {
    handleClick: function () {
      if (this.isFolder) {
        this.isOpen = !this.isOpen
      }
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  meta() {
    return {
      title: 'Settings'
    }
  }
}
</script>

<style scoped>
.root {
  cursor: pointer;
}

.router-link-exact-active {
  background: #0275d8;
  color: #fff !important;
}
.nav-link {
  color: #333;
}

</style>