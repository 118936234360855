<template>
  <div class="d-flex" v-if="activity != null">
  <div class="">
    <div class="d-flex flex-column h-100">
      <div class="d-flex flex-column">
        <div class="d-flex">
          <div class="d-flex flex-column align-items-start flex-fill">
            <div :style="mediumorbottomPathStyle" :class="separatorWidth" class="flex-fill"></div>
            <div :class="separatorActivityEnd" :style="pathColor" style="min-width: 1rem;"></div>
            <div :style="mediumortopPathStyle" :class="separatorWidth" class="flex-fill"></div>
          </div>
          <div class="d-flex flex-column">
            
            <div v-if="activity.configured" class="dropdown dropend">
              <div :class="shape" :style="activityStyle" data-bs-toggle="dropdown">
                <div class="d-flex align-items-center h-100">
                  
                  <div v-if="activity.shape == 4" class="d-flex align-items-center" style="height: 50px;">
                    <div :style="activityTextStyle" class="py-1 px-3 flow-activity-circle">{{ activity.name }}</div>
                  </div>
                  <div v-else v-html="icon" :style="{color: activity.iconcolor}" class="flex-fill text-center h-100 d-flex flex-column justify-content-center"></div>

                </div>  
              </div>
              <ul class="dropdown-menu" v-show="activity.contextmenu">
                <li><h6 class="dropdown-header">{{ activity.name}}</h6></li>
                <li class="dropdown-item" @click="eventEditActivity(activity)">Edit</li>
                <li class="dropdown-item" @click="eventDeleteActivity(activity)">Delete</li>
              </ul> 
            </div>

            <div v-if="!activity.configured" class="dropdown dropend">
              <button data-bs-toggle="dropdown" 
                type="button" class="btn btn-outline-secondary flow-activity-container" style="border-radius: 50px;" data-bs-auto-close="outside">
                <i class="fas fa-plus-circle"></i>
              </button>
              <ul class="dropdown-menu">
                <li><h6 class="dropdown-header">Types</h6></li>
                <li class="dropdown-item dropdown dropend" v-for="realItem in activityTypes" :key="realItem.category.name">
                  <div data-bs-toggle="dropdown" data-bs-offset="0,20">
                    <span v-html="realItem.category.icon"></span>
                    {{ realItem.category.text }}
                  </div>
                  <ul class="dropdown-menu">
                    <li v-for="subactivity in realItem.items" :key="subactivity.name">
                      <button type="button" class="dropdown-item" @click="addActivity(subactivity.name)">{{ subactivity.text }}</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            
          </div>
          <div class="d-flex flex-column align-items-center flex-fill">
            <div class="flex-fill" :class="separatorWidth"></div>
            <div v-if="activity.configured && activity.output > 0" :class="separatorActivityEnd" :style="pathColor" style="min-width: 1rem;"></div>
            <div class="flex-fill" :class="separatorWidth"></div>
          </div> 
        </div>
        <div class="d-flex align-items-center flex-fill">
          <div class="pe-0 h-100" :class="separatorWidth" :style="mediumortopPathStyle"></div>
          <div class="flex-fill"></div>
          <div class="d-flex flex-column align-items-center p-2 mb-2 w-100">
            <div class="text-break text-center">
              {{ activity.shape == 4 ? '' : activity.name }}
            </div>
          </div>
          <div class="pe-0 h-100 flex-fill" :class="separatorWidth"></div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-start h-100">
        <div class="pe-0 h-100" :class="separatorWidth" :style="mediumortopPathStyle"></div>
      </div>
    </div>
  </div>
  <div class="">
    <FlowViewContentActivity
      v-for="item in allChilds" :key="item.id"
      @new-activity="eventNewActivity"
      @edit-activity="eventEditActivity"
      @delete-activity="eventDeleteActivity"
      :activity="item" />
  </div>
</div>
</template>

<script>
import FlowViewContentActivity from './FlowViewContentActivity.vue'
import { v4 as uuid } from 'uuid';

export default {
  name: 'FlowViewContentActivity',
  components: {
    FlowViewContentActivity
  },
  props: {
    activity: Object
  },
  methods: {
    eventNewActivity: function (data) {
      this.$emit('new-activity',data);
    },
    eventEditActivity: function (data) {
      this.$emit('edit-activity',data);
    },
    eventDeleteActivity: function (data) {
      this.$emit('delete-activity',data);
    },
    addActivity: function(typeOfActivity){
      let activity = {
        id: uuid(),
        type: typeOfActivity,
        parent: this.activity.parent,
        configured: true,
        active: false,
        position: 0,
        contextmenu: true,
        childs: [],
        config: {}
      };
      switch (typeOfActivity) {
        case 'join-part-1':
          activity.output = 1;
          activity.shape = 2;
          activity.color = '#CC7A00';
          activity.name = 'Join';
          activity.icon = '<i class="fas fa-location-arrow"></i>';
          activity.iconcolor = '#fff';
          activity.childs.push({
            id: uuid(),
            type: 'comment',
            parent: activity.id,
            configured: true,
            contextmenu: false,
            position: 0,
            output: 0,
            shape: 4,
            color: '#CCC',
            iconcolor: '#CCC',
            name: '???',
            childs: []
          });
          break;
        case 'join-part-2':
          activity.output = 1;
          activity.shape = 4;
          activity.color = '#888';
          activity.iconcolor = '#fff';
          activity.name = '???';
          break;
        case 'message':
          activity.output = 1;
          activity.shape = 1;
          activity.color = '#007ACC';
          activity.iconcolor = '#fff';
          activity.name = 'Message';
          activity.icon = '<i class="fas fa-comment"></i>';
          break;
        case 'wait':
          activity.output = 1;
          activity.shape = 2;
          activity.color = '#CC7A00';
          activity.iconcolor = '#fff';
          activity.name = 'Wait';
          activity.icon = '<i class="far fa-clock"></i>'
          break;
        case 'updatecontact':
          activity.output = 1;
          activity.shape = 1;
          activity.color = '#007ACC';
          activity.iconcolor = '#fff';
          activity.name = 'Update Contact';
          activity.icon = '<i class="fas fa-user"></i>';
          break;
        case 'if':
          activity.output = 2;
          activity.shape = 2;
          activity.color = '#CC7A00';
          activity.iconcolor = '#fff';
          activity.name = 'IF';
          activity.icon = '<i class="fas fa-question-circle"></i>';
          activity.childs.push({
            id: uuid(),
            type: 'comment',
            parent: activity.id,
            configured: true,
            contextmenu: false,
            position: 0,
            output: 1,
            shape: 4,
            color: '#CCC',
            iconcolor: '#CCC',
            name: 'Yes',
            childs: []
          });
          activity.childs.push({
            id: uuid(),
            type: 'comment',
            parent: activity.id,
            configured: true,
            contextmenu: false,
            position: 0,
            output: 1,
            shape: 4,
            color: '#CCC',
            iconcolor: '#CCC',
            name: 'No',
            childs: []
          });
          break;
        case 'random':
          activity.output = 2;
          activity.shape = 2;
          activity.color = '#CC7A00';
          activity.iconcolor = '#fff';
          activity.name = 'Random';
          activity.icon = '<i class="fas fa-random"></i>';
          activity.childs.push({
            id: uuid(),
            type: 'comment',
            parent: activity.id,
            configured: true,
            contextmenu: false,
            position: 0,
            output: 1,
            shape: 4,
            color: '#CCC',
            iconcolor: '#CCC',
            name: '25%',
            childs: []
          });
          activity.childs.push({
            id: uuid(),
            type: 'comment',
            parent: activity.id,
            configured: true,
            contextmenu: false,
            position: 0,
            output: 1,
            shape: 4,
            color: '#CCC',
            iconcolor: '#CCC',
            name: '75%',
            childs: []
          });
          break;
        case 'abtest':
          activity.output = 2;
          activity.shape = 2;
          activity.color = '#CC7A00';
          activity.iconcolor = '#fff';
          activity.name = 'AB Test';
          activity.icon = '<i class="fas fa-vial"></i>';
          activity.childs.push({
            id: uuid(),
            type: 'comment',
            parent: activity.id,
            configured: true,
            contextmenu: false,
            position: 0,
            output: 1,
            shape: 4,
            color: '#CCC',
            iconcolor: '#CCC',
            name: 'A · 50%',
            childs: []
          });
          activity.childs.push({
            id: uuid(),
            type: 'comment',
            parent: activity.id,
            configured: true,
            contextmenu: false,
            position: 0,
            output: 1,
            shape: 4,
            color: '#CCC',
            iconcolor: '#CCC',
            name: 'B · 50%',
            childs: []
          });
          break;
        case 'comment':
          activity.output = 1;
          activity.shape = 4;
          activity.color = '#CCC';
          activity.name = 'Comment';
          break;
      
        default:
          break;
      }

      this.eventNewActivity(activity);
    },
    getPosition: function(before, after){
      if (before == 0 && after == 0){ return 0; }
      if (before == 0 && after > 0){ return 1; }
      if (before > 0 && after == 0){ return 3; }
      if (after > 0){ return 2;}
      return 0;
    }
  },
  computed: {
    icon(){
      if (this.activity.icon == ''){ return; }
      if (this.activity.icon.startsWith('<')){
        return this.activity.icon;
      } else {
        return /*html*/`<img :src="activity.icon"/>`;
      }
      
    },
    activityStyle(){
      if (this.activity.shape != 4){
        return {
          backgroundColor: this.activity.active ? this.activity.color : this.inactivecolor
        };
      } else {
        return null;
      }
    },
    activityTextStyle(){
      return {
        backgroundColor: '#fff',
        borderWidth: '2px',
        borderColor: this.activity.color,
        borderStyle: 'solid'
      };
    },
    allChilds(){
      return this.activity.childs.concat(this.addButtons);
    },
    emptyOutputs(){
      return (this.activity.output - this.activity.childs.length)
    },
    shape() {
      if(!this.activity){return;}
      let clase = [];
      switch (this.activity.shape) {
        case 0: clase.push('flow-activity-square'); break;
        case 1: clase.push('flow-activity-circle'); break;
        case 2: clase.push('flow-activity-diamond'); break;
        case 3: clase.push('flow-activity-hexagon'); break;
        default: clase.push('flow-activity-rect'); break;
      }
      if(this.activity.shape != 4){
        clase.push('flow-activity-container');
      }
      return clase.join(' ');
    },
    addButtons(){
      let btns = [];
      
      let outs = this.emptyOutputs;
      if (outs == 0){ return btns; }

      for (let index = 0; index < outs; index++) {
        let beforeButton = this.activity.childs.length + index;
        let btn = {
          id: uuid(),
          parent: this.activity.id,
          configured: false,
          name: '',
          type: 'add-activity-button',
          output: 0,
          color: '#333',
          position: this.getPosition(beforeButton, outs - index - 1),
          childs: []
        };
        btns.push(btn);
      }          

      return btns;
    },
    mediumorbottomPathStyle() {
      return this.activity.position == 2 || this.activity.position == 3 ?  this.pathColor : null;
    },
    mediumortopPathStyle() {
      return this.activity.position == 1 ||  this.activity.position == 2 ?  this.pathColor : null;
    },
    bottomPathStyle() {
      return this.activity.position == 3 ?  this.pathColor : null;
    },
    separatorActivityEnd(){
      return 'pt-1 w-100';
    },
    separatorActivity(){
      return 'ps-3 pt-1';
    },
    separatorWidth(){
      return 'ps-1';
    }
  },
  data() {
    return {
      title: 'vue',
      inactivecolor: '#ccc',
      pathColor: {
        backgroundColor: '#ccc'
      },
      activityTypes: [
        {
          category: {
            name: 'action',
            text: 'Action Activity',
            icon: /*html*/`<span class="d-inline-block p-2 me-2" style="border-radius:10px;background-color:#007ACC;"></span>`
          },
          items: [
            {
              name: 'message',
              text: 'Message'
            },
            {
              name: 'updatecontact',
              text: 'Update Contact'
            }
          ]
        },        
        {
          category: {
            name: 'flow',
            text: 'Flow Activity',
            icon: /*html*/`<span class="d-inline-block p-2 me-2 flow-activity-diamond" style="border-radius:4px;background-color:#CC7A00;"></span>`
          },
          items: [
            {
              name: 'wait',
              text: 'Wait'
            },
            {
              name: 'if',
              text: 'IF'
            },
            {
              name: 'random',
              text: 'Random'
            },
            {
              name: 'abtest',
              text: 'AB Test (deprioritized)'
            },
            {
              name: 'join-part-1',
              text: 'Join (Start)'
            },
            {
              name: 'join-part-2',
              text: 'Join (End)'
            }
          ]
        },
        {
          category: {
            name: 'sandbox',
            text: 'Sandbox Activity',
            icon: /*html*/`<i class="fas fa-box-open d-inline-block me-2" style="color:#888;"></i>`
          },
          items: [
            {
              name: 'comment',
              text: 'Comment'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>

.flow-activity-container {
  width: 50px;
  height: 50px;
  overflow: hidden;
}
.flow-activity-circle {
  border-radius: 500px;
}
.flow-activity-square {
  border-radius: 6px;
}
.flow-activity-diamond {
  border-radius: 6px;
  transform: rotate(45deg) scale(0.8);
}
.flow-activity-diamond > div {
  transform:  scale(1.2) rotate(-45deg);
}

</style>