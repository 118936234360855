<template>
  <h3>Error 404<br>NotFound</h3>
</template>

<script>
export default {
  name: 'Error404'
}
</script>

<style>

</style>