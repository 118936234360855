<template>
  <div class="d-flex flex-column h-100">
    <div class="flex-fill">

      <div v-if="state == 'loading' " class="text-center my-4">
        <div class="spinner-border text-primary"></div>
        <div class="mt-3">Loading...</div>
      </div>
      
      <div v-if="state == 'error' " class="text-center my-4">
        <i class="fas fa-exclamation-triangle fa-3x text-danger"></i>
        <div class="mt-3">Something went wrong...</div>
        <div class="mt-2 text-muted">{{ errorMessage }}</div>
        <p v-if="!showErrorDetails" class="mt-3">
          <button class="btn btn-link" type="button" @click="showErrorDetailsNow">
            More details
          </button>
        </p>
        <div v-if="showErrorDetails">
          <div class="card card-body m-3 text-start">
            {{ errorStack }}
          </div>
        </div>
      </div>

      <div v-if="state == 'loaded'">
        <div class="p-2 bg-light border-bottom d-flex justify-content-between align-items-center">
          <span>{{ page.name }}</span>
          <div class="d-flex">
            <div v-for="(e,index) in pageCount" :key="index" :style="pageIndicatorStyle(index)"></div>  
          </div>            
        </div>
        <div v-for="section in page.sections" :key="section.id">
          <div class="p-2 bg-light border-bottom border-top">{{ section.name }}</div>
          <div class="row g-2 py-2 px-3">
            <FormField
              v-for="field in section.fields"
              :field="field"
              :key="field.id"
              @value-changed="handleValueChanged"
              @value-error="handleValueError"
            />
          </div>
        </div>
      </div>

    </div>
    <div class="bg-light d-flex justify-content-between align-items-center p-2 border-top">
      <button @click="handleCancel" class="btn btn-outline-secondary">Cancel</button>
      <span v-if="nextPageDisabled" class="text-danger">{{fieldErrors.length + ' errors!'}}</span>
      <span>
        <button v-if="!isFirstPage" @click="handlePreviousPage" class="btn btn-outline-secondary me-2">Back</button>
        <button v-if="!isLastPage" @click="handleNextPage" class="btn btn-primary" :disabled="nextPageDisabled">Next</button>
        <button v-if="isLastPage" @click="handleDone" class="btn btn-primary" :disabled="nextPageDisabled">Done</button>
      </span>
    </div>
  </div>
</template>

<script>
import FlowActivityService from "../services/FlowActivityService";
import FormField  from './FormField';
// import { v4 as uuid } from 'uuid';

export default {
  name: 'FlowViewContentSidebar',
  components: {
    FormField
  },
  props: {
    activity: Object
  },
  computed: {
    nextPageDisabled(){
      return this.fieldErrors.length > 0;
    },
    isFirstPage(){
      return (this.pageIndex == 0)
    },
    isLastPage(){
      return (this.pageIndex == this.pageCount - 1)
    }
  },
  created: function(){
    this.updateActivityPage(this.activity);
  },
  watch: {
    activity: function(newValue){
      this.updateActivityPage(newValue);
    }
  },
  methods:{
    updateActivityPage: function(act){
      this.newActivity = act;
      this.newActivityConfig = act.config;
      this.pageInputs = [];
      this.fieldErrors = [];
      this.pageIndex = 0;
      this.getConfigPage();
    },
    showErrorDetailsNow: function(){
      this.showErrorDetails = true;
    },
    getConfigPage: function(){

      var data = {
        pageIndex: this.pageIndex,
        pageInputs: this.pageInputs,
        config: this.newActivityConfig
      };

      this.state = 'loading';
      setTimeout(() => {
         FlowActivityService.config('sendmessage',data)
        .then(response => {

          this.pageCount = response.data.pageCount;
          this.page = response.data.page;
          this.newActivityConfig = response.data.config;
          this.newActivity.name = response.data.activity.name || this.newActivity.name;
          this.newActivity.output = response.data.activity.output || this.newActivity.output;
          this.newActivity.outputnames = response.data.activity.outputnames || this.newActivity.outputnames || undefined;
          this.state = 'loaded';

          this.fieldErrors = [];
          this.pageInputs = {};
        })
        .catch(e => {
          this.state = 'error';
          this.errorMessage = e.message;
          this.errorStack = e.stack;

          this.fieldErrors = [];
          this.pageInputs = {};
        });
      }, 2);
     
    },
    pageIndicatorStyle: function(pageIndex){
      let colors = {
        active: '#007ACC',
        inactive: '#888'
      };
      let styles = {
        borderRadius: '10px',
        width: '10px',
        height: '10px',
        marginLeft: '5px'
      }

      if (pageIndex == this.pageIndex){
        styles.borderWidth = '3px';
        styles.borderStyle = 'solid';
        styles.borderColor = colors.active;
      } else if (pageIndex < this.pageIndex) {
        styles.backgroundColor = colors.active;
      } else {
        styles.backgroundColor = colors.inactive;
      }

      return styles;

    },
    handleNextPage: function(){
      this.pageIndex += 1;
      this.getConfigPage();
    },
    handlePreviousPage: function(){
      this.pageIndex -= 1;
      this.getConfigPage();
    },
    handleValueChanged: function(param){
      this.somethingChanged = true;
      this.pageInputs[param.field.name] = param.newValue;
      this.removeError(param.field.id);
    },
    handleValueError: function(param){
      this.removeError(param.field.id);
      this.fieldErrors.push(param);
    },
    removeError(fieldId){
      this.fieldErrors = this.fieldErrors.filter(function(elem){
        return elem.field.id != fieldId;
      });
    },
    handleCancel: function(){
      if (this.somethingChanged){
        if (confirm('There are unsaved changes.\nClose anyway?') == false){ return; }
      }
      this.$emit('close-sidepanel');
    },
    handleDone: function(){
      this.somethingChanged = false;

      this.newActivity = this.activity;
      this.newActivity.active = true;
      this.newActivity.config = this.newActivityConfig;
      
      this.$emit('activity-edited', this.newActivity);
      this.$emit('close-sidepanel');      
    }
  },
  data() {
    return {
      state: 'loading',
      errorMessage: '',
      errorStack: '',
      showErrorDetails: false,
      somethingChanged: false,
      fieldErrors: [],
      pageInputs: {},
      pageIndex: 0,
      pageCount: 5,
      page: {},
      newActivity: {},
      newActivityConfig: {}
    }
  }
}
</script>

<style scoped>

</style>