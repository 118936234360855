<template>
  <div class="d-flex flex-column flex-fill">
    <div class="row g-0">
      <div class="col-12 p-3" style="background: #eee;">
        <h3>Settings</h3>
      </div>
    </div>
    <div class="row g-0 flex-fill d-flex align-items-stretch">
      <div v-show="showSidebar" class="col-xxl-2 col-lg-3 col-md-4 col-sm-4 col-12 border-end">
        <SettingsPageSidebar />
      </div>
      <div class="col">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsPageSidebar from './SettingsPageSidebar.vue'
export default {
  components: {
    SettingsPageSidebar
  },
  data() {
    return {
      showSidebar: true
    }
  },
  meta() {
    return {
      title: 'Settings'
    }
  }
}
</script>

<style>

</style>