import http from "../http-common";

class FlowActivityService {

  config(activity, body) {
    return http.post(`/activity/${activity}/config`,body);
  }

  run(host, body) {
    // Igual creo que esta funcion iria en el backend, no acá..
    return http.post(`${host}/run`,body);
  }

}

export default new FlowActivityService();