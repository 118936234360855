<template>
  <div class="h-100 d-flex flex-column" style="max-height: 100vh;min-height: 100vh;">
    <link rel="stylesheet" 
        href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" 
        integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" 
        crossorigin="anonymous">
    <header class="navbar navbar-expand-lg navbar-light bg-light py-0 justify-content-between px-2">
      <div>
        <h3 class="float-md-start mb-0 mx-2"><router-link class="navbar-brand" :to="{ name:'home' }">{{ title }}</router-link></h3>
        <nav class="nav nav-masthead justify-content-center float-md-end">
          <router-link class="nav-link" :to="{ name:'home' }">Home</router-link>
          <router-link class="nav-link" :to="{ name:'flows' }">Flows</router-link>
          <router-link class="nav-link" :to="{ name:'settings' }">Settings</router-link>
        </nav>
      </div>
      <ul class="nav nav-masthead">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#">{{ selectedBot.name }}</a>
          <ul class="dropdown-menu dropdown-menu-lg-end">
            <li v-for="(bot, index) in bots" :key="bot.name"><a class="dropdown-item" href="#" @click="changeBot(index)" :key="bot.id">{{ bot.name }}</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#">Create new bot</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle mx-2" data-bs-toggle="dropdown" href="#">{{ user.name }}</a>
          <ul class="dropdown-menu dropdown-menu-lg-end">
            <li><button class="dropdown-item" type="button">Settings</button></li>
            <li><a class="dropdown-item" href="logout.php">Log out</a></li>
          </ul>
        </li>
      </ul>
    </header>
    <!--router-view v-slot="{ Component }">
      <transition
        enter-active-class="animate__animated animate__backInUp">
        <component :is="Component" />
      </transition>
    </router-view-->
    <router-view/>
  </div>
</template>

<script>

export default ({
  data: function(){
    return {
      title: 'Telegram Bot Studio',
      selectedBot: {
        id: '123',
        name: 'Bot DEV'
      },
      bots: [],
      user: {
        name: 'asdf'
      }
    }
  },
  methods: {
    changeBot(index) {
      this.selectedBot = this.bots[index];
      this.$emit('bot-changed', this.selectedBot.id)
    }
  }
})
</script>

<style scoped>

.nav-masthead .nav-link {
  padding: 0.50rem 0.50rem;
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
  border-bottom: 0.25rem solid transparent;
}
.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}
.nav-masthead .router-link-exact-active {
  color: #000;
  border-bottom: 0.25rem solid #000;
}

</style>
