<template>
  <div class="row g-0 h-100">
    <div v-if="showEditActivity" class="col-xl-3 col-md-4 col-sm-6 col-12 h-100 border-end">
      <FlowViewContentSidebar
        :activity="selectedActivity"
        @activity-edited="handleActivityEdited"
        @close-sidepanel="handleCloseSidepanel"
      />
    </div>
    <div class="col pt-3 h-100" style="overflow: auto;">
      <FlowViewContentActivity
        :activity="activity" 
        @new-activity="handleNewActivity" 
        @edit-activity="handleEditActivity" 
        @delete-activity="handleDeleteActivity"
      />
    </div>
  </div>
</template>

<script>
import FlowViewContentSidebar  from './FlowViewContentSidebar';
import FlowViewContentActivity  from './FlowViewContentActivity';
import { v4 as uuid } from 'uuid';

export default {
  name: 'FlowViewContent',
  components: {
    FlowViewContentSidebar,
    FlowViewContentActivity
  },
  props: {
    flowId: String
  },
  methods: {
    handleEditActivity: function(event){
      this.selectedActivity = event;
      this.showEditActivity = true;
    },
    handleActivityEdited: function(newActivity){
      this.selectedActivity = newActivity;
      this.updateSelectedActivity();
    },
    handleCloseSidepanel: function(){
      this.showEditActivity = false;
    },
    handleDeleteActivity: function(event){
      let r = confirm('Delete ' + event.name + ' ?');
      if (r == false){ return; }

      this.handleCloseSidepanel();

      //let vm = this;
      function deleteNode(rootNode, nodeToDelete){
        let node = rootNode;

        for (let index = 0; index < node.childs.length; index++) {
          let childNode = node.childs[index];
          if (childNode.id == nodeToDelete.id){

            if(childNode.output == 1 && childNode.childs.length == 1
              && childNode.type != 'join-part-1'
              ){
              let subChildNode = childNode.childs[0];
              subChildNode.parent = childNode.parent;
              node.childs.splice(index, 1);
              node.childs.splice(index, 0, subChildNode);
            } else {
              node.childs.splice(index, 1);
            }
            
          } else {
            childNode = deleteNode(childNode, nodeToDelete);
            node.childs[index] = childNode;
          }
        }

        return node;
      }
      
      return deleteNode(this.activity, event);

    },
    getPosition: function(before, after){
      if (before == 0 && after == 0){ return 0; }
      if (before == 0 && after > 0){ return 1; }
      if (before > 0 && after == 0){ return 3; }
      if (after > 0){ return 2;}
      return 0;
    },
    orderPaths: function(masterRootNode){
      let vm = this;
      function orderChildNodes(rootNode){
        let node = rootNode;

        for (let index = 0; index < node.childs.length; index++) {
          let childNode = node.childs[index];
          childNode.position = vm.getPosition(index, node.output - index - 1);
          childNode = orderChildNodes(childNode);
          node.childs[index] = childNode;
        }

        return node;
      }
      
      return orderChildNodes(masterRootNode);
    },
    updateSelectedActivity: function(){
      
      function searchAndChangeNode(rootNode,id,newNode){
        let node = rootNode;
        if (node.id == id){
          // node
          node = newNode;
        } else {
          // childs
          for (let index = 0; index < node.childs.length; index++) {
            node.childs[index] = searchAndChangeNode(node.childs[index], id, newNode); 
          }
        }
        return node;
      }

      let newRootActivity = searchAndChangeNode(this.activity, this.selectedActivity.id, this.selectedActivity);
      newRootActivity = this.orderPaths(newRootActivity);
      this.activity = newRootActivity;
      
    },
    handleNewActivity: function(event){

      let parentId = event.parent;
      let newNodeFromEvent = event; // Cambiar por una activity real

      function searchAndChangeNode(rootNode,id,newNode){
        let node = rootNode;
        if (node.id == id){
          // node
          node.childs.push(newNode);
        } else {
          // childs
          for (let index = 0; index < node.childs.length; index++) {
            node.childs[index] = searchAndChangeNode(node.childs[index], id, newNode); 
          }
        }
        return node;
      }

      let newRootActivity = searchAndChangeNode(this.activity, parentId, newNodeFromEvent);
      newRootActivity = this.orderPaths(newRootActivity);
      this.activity = newRootActivity;
      
    }
  },
  data() {
    return {
      enum_NODE_POSITION: {
        UNIQUE: 0,
        TOP: 1,
        MIDDLE: 2,
        BOTTOM: 3
      },
      enum_SHAPE: {
        SQUARE: 0,
        CIRCLE: 1,
        DIAMOND: 2,
        HEXAGON: 3,
        PILL: 4
      },
      showEditActivity: false,
      activity: {
        name: 'Entry',
        id: uuid(),
        type: 'entry',
        configured: true,
        active: false,
        contextmenu: true,
        position: 0,
        output: 1,
        shape: 0,
        icon: `<i class="fas fa-bolt" style="font-size:1.5rem;color:#fff;"></i>`,
        color: '#7acc00',
        iconcolor: '#fff',
        config: {},
        childs: []
      },
      selectedActivity: {}
    }
  }
}
</script>

<style scoped>

</style>