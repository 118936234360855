<template>
  <div class="card m-2">
    <div v-if="flows.length == 0" class="card-body">Nothing to show here.</div>
    <div v-if="flows.length > 0" class="card-body">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
            <th scope="col">#</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="flow in flows"
            v-bind:key="flow.id"
            v-bind:title="flow.name"
          >
            <td>{{ flow.name }}</td>
            <td>{{ flow.description }}</td>
            <td>
              <router-link
                class="btn btn-outline-primary me-2"
                :to="{ path: 'flow/' }"
                >Edit</router-link
              >
              <button
                v-on:click="deleteFlow"
                type="button"
                class="btn btn-outline-danger me-2"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="flows.length > 0" class="card-footer">
      Rows: {{ flows.length }}
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      flows: [],
    };
  },
  created: function () {
    this.loadFlows();
  },
  methods: {
    deleteFlow: function(event){
      alert('deleted!' + event);
    },
    loadFlows: function () {
     
    }
  }
};
</script>

<style scoped>

</style>
