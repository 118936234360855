<template>
  <div class="d-flex flex-column flex-fill h-100">
    <div class="row g-0 w-100" style="background: #eee;">
      <div class="col-9 p-3">
        <h3>Flow</h3>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <router-link class="breadcrumb-item" :to="{ name:'home' }">Home</router-link>
            <router-link class="breadcrumb-item" :to="{ name:'flows' }">Flows</router-link>
            <li class="breadcrumb-item active">New</li>
          </ol>
        </nav>
      </div>
      <div class="col-3 align-items-center justify-content-end d-flex p-3">
        <button class="btn btn-outline-danger disabled">Eliminar</button>
      </div>
    </div>
    <div class="row g-0 w-100 flex-fill">
      <div class="col">
        <FlowViewContent :flow-id="flowId" />
      </div>
    </div>
  </div>
</template>

<script>
import FlowViewContent from './FlowViewContent.vue'
import { v4 as uuid } from 'uuid';

export default {
  name: 'FlowViev',
  components: {
    FlowViewContent
  },
  data() {
    return {
      flowId: uuid()
    }
  },
  create: function(){
    this.loadFlow();
  },
  methods:{
    loadFlow: function(){
      
    }
  },
  meta() {
    return {
      title: 'Flow'
    }
  }
}
</script>

<style scoped>

</style>