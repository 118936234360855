import { createWebHistory, createRouter } from "vue-router";

import Error404 from "./components/404.vue"
import HomePage from "./components/DashboardPage.vue";
import FlowPage from "./components/FlowPage.vue";
import FlowView from "./components/FlowView.vue";
import SettingsPage from "./components/SettingsPage.vue";

const routes = [
  {
    name: "home",
    path: "/",
    component: HomePage,
    meta:{ title:'Dashboard' }
  },
  {
    name: "flows",
    path: "/flow",
    component: FlowPage,
    meta:{ title:'Flows' }
  },
  {
    name: "flow",
    path: "/flow/:id",
    component: FlowView,
    meta:{ title:'Flow' }
  },
  {
    name: "settings-menu",
    path: "/settings",
    component: SettingsPage,
    meta:{ title:'Settings' },
    children: [
        { name: 'settings-flow', path: 'qwer', component: Error404 },
        { name: 'unkown', path: 'unkown' },
        { name: 'settings', path:'', component: Error404 }

      ]
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const DEFAULT_TITLE = "Telegram Bot Studio";
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + ' · ' + DEFAULT_TITLE || DEFAULT_TITLE
  }
  next()
});

export default router;
